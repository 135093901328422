.btn-grad-red {
  background-color: var(--text-color) !important;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  // box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad-red:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-gradient {
  color: white;
  font-weight: bolder;
  background-color: var(--text-color) !important;
  &:hover {
    color: white;
    background-color: #c71111 !important;
  }
}
