:root {
  --primary-color: #d9d9d9;
  --secondary-color: #ffce03;
  --background-color: var(--primary-color);
  --text-color: #880808;
  --button-color: #880808;
  --icon-color: #bcbcbc;
  --secondary-bg-color: #f0ffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: white;
  // background: linear-gradient(
  //   to right,
  //   var(--background-color),
  //   var(--secondary-color)
  // );
  color: var(--text-color);
}

.home,
.mint,
.footer,
.grinder-container,
.app-header {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    font-family: Horizon, Montserrat, Arial, Helvetica, sans-serif;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.home,
.mint,
.footer,
.grinder-container,
.app-header {
  p,
  span,
  div {
    font-family: Horizon, Arial, Helvetica, sans-serif;
  }
}

// p,
// span,
// div {
//   font-family: Horizon, Arial, Helvetica, sans-serif;
//   // font-family: SuperMario;
//   // font-family: ManaSpc;
// }

#particles {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: #11111100;
  position: fixed;

  .circle-container {
    z-index: 0;
    $particleNum: 200;
    $particleColor: hsl(180, 100%, 80%);

    position: absolute;
    transform: translateY(-40vh);
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .circle {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      mix-blend-mode: screen;
      // background-image: radial-gradient(
      //   hsl(180, 100%, 80%),
      //   hsl(180, 100%, 80%) 10%,
      //   hsla(180, 100%, 80%, 0) 56%
      // );

      // animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

      @keyframes fade-frames {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.7;
        }

        100% {
          opacity: 1;
        }
      }

      @keyframes scale-frames {
        0% {
          transform: scale3d(0.4, 0.4, 1);
        }

        50% {
          transform: scale3d(2.2, 2.2, 1);
        }

        100% {
          transform: scale3d(0.4, 0.4, 1);
        }
      }
    }

    $particleBaseSize: 200;

    @for $i from 1 through $particleNum {
      &:nth-child(#{$i}) {
        $circleSize: random($particleBaseSize);
        width: $circleSize + px;
        height: $circleSize + px;

        $max: 65 + random(30);
        $startPositionY: random(10) + $max;
        $framesName: "move-frames-" + $i;
        $moveDuration: 15000 + random(9000) + ms;

        animation-name: #{$framesName};
        animation-duration: $moveDuration;
        animation-delay: ($i - 1) * 500 + ms;

        img {
          width: 100%;
          height: 100%;
          opacity: 0.3;
        }

        @keyframes #{$framesName} {
          from {
            transform: translate3d(#{random($max) + vw}, #{$startPositionY + vh}, 0);
          }

          to {
            transform: translate3d(#{random($max) + vw}, #{- $startPositionY - random(30) + vh}, 0);
          }
        }

        .circle {
          animation-delay: random(1) + ms;
        }
      }
    }
  }
}

@import "./styles/styles.scss";
@import "./styles/colors.scss";
@import "./styles/timeline.scss";
@import "./styles/timeline-horizontal.scss";
@import "./styles/loading.scss";
@import "./styles/circle-preloader.scss";
